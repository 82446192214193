<template>
  <div class="index-container">
    <div class="bread">经营状况</div>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="9" :xl="9">
        <el-card shadow="never">
          <div slot="header">
            <span>租车</span>
          </div>
          <el-row :gutter="20" class="item">

            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <div class="info-box" @click="handleGoRental(3)">
                <p class="value">{{ showNumber.rentedCar }}</p>
                <p class="title-in">已出租</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <div class="info-box" @click="handleGoRental(1)">
                <p class="value">{{ showNumber.notRentCar }}</p>
                <p class="title-in">空闲中</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <div class="info-box" @click="handleGoRental(2)">
                <p class="value">{{ showNumber.waitRentCar }}</p>
                <p class="title-in">出租中</p>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="24" :md="10" :lg="6" :xl="6">
        <el-card shadow="never">
          <div slot="header">
            <span>客户</span>
          </div>
          <el-row :gutter="20" class="item">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <div class="info-box" @click="goToCustomer">
                <p class="value">{{ showNumber.customRentedCar }}</p>
                <p class="title-in">已租客户</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <div class="info-box" @click="goToCustomer">
                <p class="value">{{ showNumber.customNotRentCar }}</p>
                <p class="title-in">未租客户</p>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="24" :md="10" :lg="9" :xl="9">
        <el-card shadow="never">
          <div slot="header">
            <span>售车</span>
          </div>
          <el-row :gutter="20" class="item">
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <div class="info-box">
                <p class="value" @click="handleGoSale('6')">{{ showNumber.saledCar }}</p>
                <p class="title-in">已出售</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <div class="info-box">
                <p class="value" @click="handleGoSale('4')">{{ showNumber.notSaleCar }}</p>
                <p class="title-in">未出售</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <div class="info-box">
                <p class="value" @click="handleGoSale('5')">{{ showNumber.waitSaleCar }}</p>
                <p class="title-in">出售中</p>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-card shadow="never">
          <div slot="header">
            <span><b>车管</b></span>
          </div>
          <el-row :gutter="20" class="item">
            <el-col
              :xs="24"
              :sm="12"
              :md="8"
              :lg="3"
              :xl="3"
              v-if="showRouter('admin')"
            >
              <div class="info-box" @click="addCar">
                <p class="value">
                  <img src="../../img/新增车辆@2x.png" />
                </p>
                <p class="title-in">新增车辆</p>
              </div>
            </el-col>
            <el-col
              :xs="24"
              :sm="12"
              :md="8"
              :lg="3"
              :xl="3"
              v-if="showRouter('admin')"
            >
              <div class="info-box" @click="addCustomer">
                <p class="value">
                  <img src="../../img/新增客户@2x.png" />
                </p>
                <p class="title-in">新增客户</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="3" :xl="3">
              <div class="info-box" @click="handleJump('')">
                <p class="value">
                  <img src="../../img/车辆入库@2x.png" />
                </p>
                <p class="title-in">车辆入库</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="3" :xl="3">
              <div class="info-box" @click="handleJump('')">
                <p class="value">
                  <img src="../../img/车辆年检@2x.png" />
                </p>
                <p class="title-in">车辆年检</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="3" :xl="3">
              <div class="info-box" @click="handleJump('bxtx')">
                <p class="value">
                  <img src="../../img/车辆保险@2x.png" />
                </p>
                <p class="title-in">车辆保险</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="3" :xl="3">
              <div class="info-box" @click="handleJump('')">
                <p class="value">
                  <img src="../../img/车辆保养@2x.png" />
                </p>
                <p class="title-in">车辆保养</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="3" :xl="3">
              <div class="info-box" @click="handleJump('')">
                <p class="value">
                  <img src="../../img/车辆维修@2x.png" />
                </p>
                <p class="title-in">车辆维修</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="3" :xl="3">
              <div class="info-box" @click="handleJump('')">
                <p class="value">
                  <img src="../../img/维修厂商@2x.png" />
                </p>
                <p class="title-in">维修厂商</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="3" :xl="3">
              <div class="info-box" @click="handleJump('')">
                <p class="value">
                  <img src="../../img/车辆出险@2x.png" />
                </p>
                <p class="title-in">车辆出险</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="3" :xl="3">
              <div class="info-box" @click="handleJump('')">
                <p class="value">
                  <img src="../../img/交租提醒@2x.png" />
                </p>
                <p class="title-in">交租提醒</p>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-card shadow="never">
          <div slot="header">
            <span><b>销售</b></span>
          </div>
          <el-row :gutter="20" class="item">
            <el-col :xs="24" :sm="12" :md="8" :lg="3" :xl="3" v-if="showRouter('admin')">
              <div class="info-box" @click="handleJump('zbcz')">
                <p class="value">
                  <img src="../../img/准备出租@2x.png" />
                </p>
                <p class="title-in">准备出租</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="3" :xl="3" v-if="showRouter('admin')">
              <div class="info-box" @click="handleJump('qxzc')">
                <p class="value">
                  <img src="../../img/取消出租@2x.png" />
                </p>
                <p class="title-in">取消出租</p>
              </div>
            </el-col>
            <el-col
              :xs="24"
              :sm="12"
              :md="8"
              :lg="3"
              :xl="3"
              v-if="showRouter('admin')"
            >
              <div class="info-box" @click="handleJump('clcs')">
                <p class="value">
                  <img src="../../img/车辆出售@2x.png" />
                </p>
                <p class="title-in">车辆出售</p>
              </div>
            </el-col>
            <el-col
              :xs="24"
              :sm="12"
              :md="8"
              :lg="3"
              :xl="3"
              v-if="showRouter('admin')"
            >
              <div class="info-box" @click="handleJump('sign')">
                <p class="value">
                  <img src="../../img/车辆签约@2x.png" />
                </p>
                <p class="title-in">车辆签约</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="3" :xl="3">
              <div class="info-box" @click="handleJump('')">
                <p class="value">
                  <img src="../../img/车辆续约@2x.png" />
                </p>
                <p class="title-in">车辆续约</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="3" :xl="3">
              <div class="info-box" @click="handleJump('')">
                <p class="value">
                  <img src="../../img/合约到期@2x.png" />
                </p>
                <p class="title-in">合约到期</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="3" :xl="3">
              <div class="info-box" @click="handleJump('position')">
                <p class="value">
                  <img src="../../img/职位管理@2x.png" />
                </p>
                <p class="title-in">职位管理</p>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-card shadow="never" @click="handleJump('')">
          <div slot="header">
            <span><b>财务权限</b></span>
          </div>
          <el-row :gutter="20" class="item">
            <el-col :xs="24" :sm="12" :md="8" :lg="3" :xl="3">
              <div class="info-box" @click="handleJump('ysjl')">
                <p class="value">
                  <img src="../../img/应收记录@2x.png" />
                </p>
                <p class="title-in">应收记录</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="3" :xl="3">
              <div class="info-box" @click="handleJump('yfjl')">
                <p class="value">
                  <img src="../../img/应付记录@2x.png" />
                </p>
                <p class="title-in">应付记录</p>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <add-customer ref="customer" @getDashBoard="getDashBoard"></add-customer>
    <edit-car ref="car" @getDashBoard="getDashBoard"></edit-car>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { kzt, getJurisdiction, getUserInfo } from "@/utils/api/own";
import AddCustomer from "@/components/customer";
import EditCar from "@/components/EditCar";
import { getStore, setStore } from "@/utils/storage";

export default {
  name: "index",
  components: {
    AddCustomer,
    EditCar
  },
  data() {
    return {
      showNumber: {
        customNotRentCar: "0",
        customRentedCar: "0",
        notRentCar: "0",
        notSaleCar: "0",
        rentedCar: "0",
        saledCar: "0",
        waitRentCar: "0",
        waitSaleCar: "0"
      }
    };
  },

  created() {
    this.getDashBoard();
    this.getOwnAuthor();
  },
  methods: {
    showRouter(permission) {
      if (permission === "admin") {
        return true;
      } else {
        let result = this.$store.state.permissions.findIndex(
          item => item === permission
        );
        if (result !== -1) {
          return true;
        } else {
          return false;
        }
      }
    },
    //新增客户
    addCustomer() {
      this.$refs["customer"].showEdit();
    },
    //新增车辆
    addCar() {
      this.$refs["car"].showEdit();
    },
    /*数据交互*/
    getDashBoard() {
      kzt().then(res => {
        this.showNumber = res.data;
      });
    },
    goToCustomer() {
      this.$router.push({ path: "/customer" });
    },
    getOwnAuthor() {
      getJurisdiction().then(res => {});
    },
    handleJump(value) {
      switch (value) {
        case "zbcz":
          this.$router.push({ name: "准备出租" });
          break;
        case "qxzc":
          this.$router.push({ name: "取消出租" });
          break;
        case "clcs":
          this.$router.push({ name: "车辆出售" });
          break;
        case "position":
          this.$router.push({ name: "职位管理" });
          break;
        case "bxtx":
          this.$router.push({ name: "保险提醒" });
          break;
        case "ysjl":
          this.$router.push({ path: "/financial?state=3" });
          break;
        case "yfjl":
          this.$router.push({ path: "/financial?state=1" });
          break;
        case "sign":
          this.$router.push({ name: "签约记录" });
          break;
        default:
          this.$message.warning("暂未开放");
      }
    },
    handleGoSale(value) {
      this.$router.push({ path: `/dashboard/clcs?sellState=${value}` });
    },
    handleGoRental(value) {
      this.$router.push({ path: `/dashboard/zc?carState=${value}` });
    }
  }
};
</script>

<style  lang="less">
.el-card {
  background-color: inherit;
  border: none;

  .el-card__header {
    border: none;
    padding: 0;
    padding-bottom: 20px;
  }

  .el-card__body {
    padding: 0;
    //display: block;

    .info-box {
      height: 100px;
      background: #ffffff;
      border-radius: 2px;
      text-align: center;
      margin-bottom: 20px;

      &:hover {
        cursor: pointer;
      }

      .value {
        height: 42px;
        font-size: 30px;
        font-weight: 500;
        color: #3c6cfc;
        line-height: 42px;
        letter-spacing: 1px;
        padding-bottom: 11px;
        transform: translateY(10px);

        img {
          width: 42px;
        }
      }

      .title-in {
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        line-height: 17px;
        transform: translateY(10px);
      }
    }
  }
}
</style>
