 <template>
  <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="45%">
    <el-form ref="form" :inline="true" :model="form" label-position="top" :rules="ruleCar" class="info">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="行驶证正面" :label-width="formLabelWidth" prop="carNum">
            <el-upload
                class="avatar-uploader"
                :action="upLoadUrl"
                :before-upload="beforeAvatarUpload"
                :headers="headers"
                :on-success="handleVechicleFrontSuccess"
                :show-file-list="false">
              <img v-if="form.vehicleLicenseZUrl" :src="form.vehicleLicenseZUrl" class="avatar">
              <div class="explain" v-else>
                <i  class="el-icon-plus avatar-uploader-icon"></i>
                <p class="words">上传行驶证正本</p>
                <p class="tips">只支持.jpg/.png格式</p>
              </div>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="行驶证反面" :label-width="formLabelWidth" prop="plate">
            <el-upload
                class="avatar-uploader"
                :action="upLoadUrl"
                :before-upload="beforeAvatarUpload"
                :headers="headers"
                :on-success="handleVechicleBackSuccess"
                :show-file-list="false">
              <img v-if="form.vehicleLicenseFUrl" :src="form.vehicleLicenseFUrl" class="avatar">
              <div class="explain" v-else>
                <i  class="el-icon-plus avatar-uploader-icon"></i>
                <p class="words">上传行驶证副本</p>
                <p class="tips">只支持.jpg/.png格式</p>
              </div>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="车辆45º照片" :label-width="formLabelWidth" prop="car45Url">
            <el-upload
                class="avatar-uploader"
                :action="upLoadUrl"
                :before-upload="beforeAvatarUpload"
                :headers="headers"
                :on-success="handleCarImgBackSuccess"
                :show-file-list="false">
              <img v-if="form.car45Url" :src="form.car45Url" class="avatar">
              <div class="explain" v-else>
                <i  class="el-icon-plus avatar-uploader-icon"></i>
                <p class="words">上传车辆45º照片</p>
                <p class="tips">只支持.jpg/.png格式</p>
              </div>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form ref="form" :model="form" :label-position="labelPosition" :rules="ruleCar" class="info">
      <el-form-item label="车牌号" :label-width="formLabelWidth" prop="carNum">
        <el-input v-model="form.carNum" autocomplete="off" placeholder="请输入车牌号"></el-input>
      </el-form-item>
      <el-form-item label="品牌" :label-width="formLabelWidth" prop="brandModel">
        <el-cascader
            v-model="form.brandModel"
            placeholder="试试搜索"
            :options="options"
            @change="handleBrand"
            filterable></el-cascader>
      </el-form-item>
      <el-form-item label="车架号" :label-width="formLabelWidth" prop="vinNo">
        <el-input v-model="form.vinNo" autocomplete="off" placeholder="请输入车架号"></el-input>
      </el-form-item>
      <el-form-item label="发动机号" :label-width="formLabelWidth" prop="engineNo">
        <el-input v-model="form.engineNo" autocomplete="off" placeholder="请输入发动机号"></el-input>
      </el-form-item>
      <el-form-item label="注册日期" :label-width="formLabelWidth" prop="registerTime">
        <el-input v-model="form.registerTime" autocomplete="off" placeholder="请输入注册日期"></el-input>

      </el-form-item>
      <el-form-item label="公里数" :label-width="formLabelWidth" prop="kilometers">
        <el-input v-model="form.kilometers" autocomplete="off" placeholder="请输入公里数" maxlength="7" type="number">
          <span slot="append">km</span>
        </el-input>
      </el-form-item>
      <el-form-item label="年检到期时间" :label-width="formLabelWidth"  prop="yearCheckUpTimeScope">
        <el-date-picker
            v-model="form.yearCheckUpTimeScope"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="车险到期时间" :label-width="formLabelWidth"  prop="insureTime">
        <el-date-picker
            v-model="form.insureTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="车辆类型" :label-width="formLabelWidth" prop="leaseCarType">
        <el-radio-group v-model="form.leaseCarType">
          <el-radio-button   v-for="item in vList" :label="item.value">{{item.label}}</el-radio-button>

        </el-radio-group>
      </el-form-item>
      <el-form-item label="运行平台" :label-width="formLabelWidth" prop="operPlatform">
        <el-radio-group v-model="form.operPlatform">
          <el-radio-button label="1">滴滴平台</el-radio-button>
          <el-radio-button label="2">美团平台</el-radio-button>
          <el-radio-button label="3">不限平台</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="能源类型" :label-width="formLabelWidth" prop="energyType">
        <el-radio-group v-model="form.energyType">
          <el-radio-button label="1">纯电动</el-radio-button>
          <el-radio-button label="2">混合动力</el-radio-button>
          <el-radio-button label="3">燃油动力</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="营运性质" :label-width="formLabelWidth" prop="carServiceType">
        <el-radio-group v-model="form.carServiceType">
          <el-radio-button label="1">营运</el-radio-button>
          <el-radio-button label="2">非营运</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="所有人" :label-width="formLabelWidth" prop="ascription">
        <el-radio-group v-model="form.ascription">
          <el-radio-button label="1">企业</el-radio-button>
          <el-radio-button label="2">个人</el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="handleAddCar">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {ocr} from "@/utils/api/tool";
import config  from "@/utils/config";
import {getStore} from "@/utils/storage";
import {getCarModeSelectVo} from "@/utils/api/car";
import {getDict} from "@/utils/api/dict";
import {addOne} from "@/utils/api/car";

export default {
  name: "EditCar",
  data(){
    return{
      dialogFormVisible:false,
      title:'新增',
      formLabelWidth:"120px",
      labelPosition:"right",
      bList:[],//品牌列表
      vList:[{

      }],//车辆类型列表
      form:{
        vehicleLicenseZUrl:'',//行驶证正面
        vehicleLicenseFUrl:'',//行驶证反面
        car45Url:'',//车照45°照片
        carNum:"",//车牌
        brandModel:"",//品牌
        vinNo:"",//车架号
        engineNo:"",//发动机号
        registerTime:'',//注册时间
        leaseCarType:"",//车辆类型，
        kilometers:"",//行驶里程
        yearCheckUpTimeScope:"",//年检到期时间
        insureTime:"",//保险到期时间
        operPlatform:"1",//运行平台
        energyType:"1",//能源类型
        carServiceType:"1",//营运性质
        ascription:"1"//所有人
      },
      options: [],
      ruleCar:{
        carNum:[
          { required: true, message: '请输入车牌号', trigger: 'blur' },
        ],
        brandModel:[
          { required: true, message: '请选择品牌', trigger: 'blur' },
        ],
        vinNo:[
          { required: true, message: '请输入车架号', trigger: 'blur' },
        ],
        engineNo:[
          { required: true, message: '请输入发动机号', trigger: 'blur' },
        ],
        registerTime:[
          { required: true, message: '请录入注册时间', trigger: 'blur' },
        ],
        leaseCarType:[
          { required: true, message: '请选择车辆类型', trigger: 'blur' },
        ],
        kilometers:[
          { required: true, message: '请输入已行驶公里数', trigger: 'blur' },
        ],
        yearCheckUpTimeScope:[
          { required: true, message: '请选择年检到期时间', trigger: 'blur' },
        ],
        insureTime:[
          { required: true, message: '请选择保险到期时间', trigger: 'blur' },
        ],
        operPlatform:[
          { required: true, message: '请选择跑单平台', trigger: 'blur' },
        ],
        energyType:[
          { required: true, message: '请选择能源类型', trigger: 'blur' },
        ],
        carServiceType:[
          { required: true, message: '请选择营运性质', trigger: 'blur' },
        ],
        ascription:[
          { required: true, message: '请选择车辆所有', trigger: 'blur' },
        ],
      },
      data: {
        cmd: 5
      },
      headers: {
          // :
        authorization: 'Bearer '+getStore('access_token')
      },
      upLoadUrl: config.baseUrl + '/app/tool/uplodFile'
    }
  },
  methods:{
    showEdit(row) {
      if (!row) {
        this.title = '新增车辆信息'
      } else {
        this.title = '编辑车辆信息'
        this.form = Object.assign({}, row)
      }
      this.dialogFormVisible = true
    },
    close(){
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form
      this.dialogFormVisible = false
      this.$emit('close')
    },
    save(){
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.$emit('save',this.form)
          this.$refs['form'].resetFields()
          this.dialogFormVisible = false
          this.form = this.$options.data().form
        } else {
          return false
        }
      })
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpg';
      const isPng = file.type === 'image/png'
      const isJPEG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 5;
      if((isJPG && isLt2M) || (isPng && isLt2M) || (isJPEG && isLt2M) ){
        return isLt2M;
      }else{
        this.$message.error('上传图片只能是 JPG或PNG 格式并小于5M');
        return  false
      }
    },
    //上传行驶证正面并ocr
    handleVechicleFrontSuccess(res, file){
      this.form.vehicleLicenseZUrl = getStore('imgBase') + res.data.url
      let data = {
        cmd: 5,
        url: getStore('imgBase') + res.data.url
      }
      ocr(data).then(res => {
        if(res.code === 0){
            let ocr_data = JSON.parse(res.data)
            try {
              let final_orc_data = ocr_data.words_result[0].license_info;
              final_orc_data.map(item=>{
                if(item.word_name === "号牌号码"){
                  this.form.carNum = item.word
                }
                if(item.word_name === "车辆识别代号"){
                  this.form.vinNo = item.word
                }
                if(item.word_name === "发动机号码"){
                  this.form.engineNo = item.word
                }
                if(item.word_name === "注册日期"){
                  this.form.registerTime = item.word
                }
              })
            }catch (e) {
              this.$message.warning("OCR识别失败，请手动填写信息！")
            }
        }
      })
    },
    handleVechicleBackSuccess(res, file){
      this.form.vehicleLicenseFUrl = getStore('imgBase') + res.data.url
    },
    handleCarImgBackSuccess(res, file){
      this.form.car45Url = getStore('imgBase') + res.data.url
    },
    //获取品牌
    handleBrand(value){
      this.form.carBrand = value[0]
      this.form.carMode = value[1]
    },
    //获取车辆类型
    getDict(){
      let data = {
        code: 'CLLX'
      }
      getDict(data).then(res=>{
        if(res.code === 0){
          this.vList = res.data
        }
      })
    },
    //品牌型号
    getCarModeSelectVo(){
      let data = {
        value:'',enterpriseId:''
      }
      getCarModeSelectVo(data).then(res=>{
        if(res.code === 0){
          this.options = res.data
        }
      })
    },
    //车辆类型
    handleLeaseCarType(e){
      this.form.leaseCarType = e
    },
    //新增车辆
    handleAddCar(){
      let data = JSON.parse(JSON.stringify(this.form))
      data.car45Url = data.car45Url.replace(getStore('imgBase'), '')
      data.vehicleLicenseFUrl = data.vehicleLicenseFUrl.replace(getStore('imgBase'), '')
      data.vehicleLicenseZUrl = data.vehicleLicenseZUrl.replace(getStore('imgBase'), '')
      addOne(data).then(res => {
        if(res.code === 0){
          this.form.carBrand = ''
          this.form.carMode = ''
          this.form.brandModel = ""
          this.$message.success('车辆添加成功')
          this.$emit('getDashBoard')
          this.close()
        }else{
          this.$message.error(res.msg)
          // this.close()
        }
      })
    }
  },
  created() {
    this.getCarModeSelectVo()
    this.getDict()
  }
}
</script>

<style  lang="less">
.el-select{
  width: 60%;
  .el-input{
    width: 100%;
  }
}

</style>
