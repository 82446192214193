/**
 * @notice 客户相关
 * @author hhq
 */
import http from "../http"
//新增客户
export const addOne =(params)=>{
    return http.postRequest('/app/tCustomer/add',params)
}
//删除客户
export const delOne =(params)=>{
    return http.postRegister('/app/tCustomer/delKu',params)
}
//获取客户详情
export const getDetail =(params)=>{
    return http.postRegister('/app/tCustomer/detail',params)
}
//编辑客户信息
export const editOne =(params)=>{
    return http.postRegister('/app/tCustomer/editKu',params)
}
//获取客户列表
export const getList =(params)=>{
    return http.postRegister('/app/tCustomer/list',params)
}
//获取客户列表
export const getListNew =(params)=>{
    return http.postRegister('/app/tCustomer/listWithSign',params)
}


