<template>
  <el-dialog :title="title" :visible.sync="dialogFormVisible" width="45%">
    <el-form ref="form" :model="form" :label-position="labelPosition" :rules="ruleUser">
      <template slot="title">标题</template>
      <el-form-item label="客户名称" :label-width="formLabelWidth" prop="name">
        <el-input v-model="form.name" autocomplete="off" placeholder="请输入客户名称"></el-input>
      </el-form-item>
      <el-form-item label="客户电话" :label-width="formLabelWidth" prop="phone" maxlength="11">
        <el-input v-model="form.phone" autocomplete="off" placeholder="请输入客户电话"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addOne } from "@/utils/api/customer";
export default {
  name: "AddCustomer",
  data() {
    var checkName = (rule, value, callback) => {
      let reg = /^[a-zA-Z\u4e00-\u9fa5]+$/g;
      if (value.length > 20) {
        callback(new Error("输入的姓名过长"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入中文或英文"));
      } else {
        callback();
      }
    };
    var checkPhone = (rule, value, callback) => {
      let reg_mobile = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
      let reg_phone = /^(0d{2,3})-?(d{7,8})$/;
      if (!reg_mobile.test(value)) {
        callback(new Error("请输入正确的手机或座机号码"));
      } else {
        callback();
      }
      if (!reg_phone.test(value)) {
        callback(new Error("请输入正确的手机或座机号码"));
      } else {
        callback();
      }
    };
    return {
      dialogFormVisible: false,
      title: "新增",
      formLabelWidth: "80px",
      labelPosition: "left",
      form: {
        name: "",
        phone: ""
      },
      ruleUser: {
        name: [
          { required: true, message: "请输入客户姓名", trigger: "blur" },
          { validator: checkName, trigger: "blur" }
        ],
        phone: [
          { required: true, message: "请输入客户电话", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    showEdit(row) {
      if (!row) {
        this.title = "新增客户信息";
      } else {
        this.title = "编辑客户信息";
        this.form = Object.assign({}, row);
      }
      this.dialogFormVisible = true;
    },
    close() {
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
      this.$emit("fetch-data");
    },
    save() {
      this.$refs["form"].validate(async valid => {
        if (valid) {
          let data = {
            customerName: this.form.name,
            customerPhone: this.form.phone
          };
          addOne(data).then(res => {
            if(res.code == 0){
              this.$message.success("添加成功")
              setTimeout(()=>{
                this.$emit('getDashBoard')
               this.close()
              }, 1000)
            }
          });
          // const { msg } = await doEdit(this.form)
          // this.$baseMessage(msg, 'success')
          // this.$refs['form'].resetFields()
          // this.dialogFormVisible = false
          this.$emit("fetch-data");
          this.form = this.$options.data().form;
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style  lang="less">
</style>
